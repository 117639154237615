import Experiences from '../../components/experiences/experiences.vue'
import NoWebBanner from '../../components/no-web-banner/no-web-banner.vue'
import GalleryBuilderExp from '../../components/gallery-builder-exp/gallery-builder-exp.vue'
import ItinerariesList from '../../components/itineraries/itineraries.vue';

//custom services
import ItineraryService from '../../services/itinerary';
import GlobalService from '../../services/global';
import OpenService from '../../services/open';

export default {
  name: 'Itinerary',

  components: {
    Experiences,
    NoWebBanner,
    GalleryBuilderExp,
    ItinerariesList
  },

  methods: {
    hideModal() {
      this.showModal = false;
      this.$router.replace({ name: 'experiences'});
    },

    fetchExperience() {
      this.loading = this.showModal = this.fetchingUser = this.fetchingReviews = true;
      return ItineraryService
        .getItinerary(this.id)
        .then(response => {

          let itinerary = response.data;
          itinerary.uname = 'l';
          this.itinerary = ItineraryService.mapItinerary(itinerary);
          document.title = this.itinerary.title;
          this.loading = this.showModal = false;

          this.fetchUser(this.id);
          
          //if (this.itinerary.totalReviews) this.fetchReviews(this.id);
          //else this.fetchingReviews = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        }); 
    },

    fetchUser(id) {
      return ItineraryService
        .fetchUser(id)
        .then(resp => {
          let user = resp.data;
          
          let firstLetter = user.name.charAt(0).toLowerCase();
          user.photo = {
            img: GlobalService.getUserPhoto(user.photos, firstLetter),
            lazy: require("@/assets/imgs/placeholder.png")
          }

          this.itinerary.user = user;
          this.fetchingUser = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingUser = false;
        });
    },

    fetchReviews(id) {
      return ItineraryService
        .fetchReviews(id)
        .then(resp => {
          this.experience.reviews = resp.data;
          this.fetchingUser = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingUser = false;
        });
    },

    zoom() {
      window.open('https://zoom.us/download', '_blank');
    },

    skype() {
      window.open('https://www.skype.com/en/get-skype/', '_blank');
    },

    googleHangouts() {
      window.open('https://apps.google.com/meet/', '_blank');
    },

    generateSlug(string) {
      return OpenService.generateSlug(string);
    }
  },

  mounted() {
    this.showModal = true;
    this.fetchExperience(this.id);
  },

  watch: {
    '$route'(to) {
      if (to.params.id != this.id) {
        this.id = to.params.id;
        window.scrollTo(0,0);
        this.fetchExperience();
      }
    }
  },

  data() {
    return {
      loading: false,
      showModal: false,

      fetchingUser: false,
      fetchingReviews: false,

      id: this.$route.params.id || '',
      itinerary: {}
    }
  }
}